import React from "react";
import Select from "react-select";
import * as styles from './multi-select.module.scss';

export const MultiSelect = ({
                               className,
                               placeholder,
                               field,
                               form,
                               options,
                               isMulti = false
                             } ) => {
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option).map((item) => item.value)
        : (option).value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value?.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : ("");
    }
  };

  return (
    <Select
      className={className + " " + styles.multiselect}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={"Bitte wählen Sie aus ..."}
      options={options}
      isMulti={isMulti}
  />
);
};

export default MultiSelect;
