export const BZH_BRANCHEN = [{"title":"Dienstleistung","code":"dienstleistung","id":"74a24cae-e8f1-11e9-a7cd-b527a4bfd3e1","createdAt":"2019-10-07T10:58:55+02:00"},{"title":"Handel","code":"handel","id":"74a30b62-e8f1-11e9-a7cd-b527a4bfd3e1","createdAt":"2019-10-07T10:58:55+02:00"},{"title":"Produktion","code":"produktion","id":"74a338b2-e8f1-11e9-a7cd-b527a4bfd3e1","createdAt":"2019-10-07T10:58:55+02:00"},{"title":"Industrie","code":"industrie","id":"74a37958-e8f1-11e9-a7cd-b527a4bfd3e1","createdAt":"2019-10-07T10:58:55+02:00"},{"title":"IT\/Software","code":"it","id":"e599af5b-5d40-11ea-8ce5-42010a9c0039","createdAt":"2020-03-03T11:19:49+01:00"},{"title":"Sonstiges","code":"other","id":"f2286893-5d40-11ea-8ce5-42010a9c0039","createdAt":"2020-03-03T11:20:11+01:00"}];

export const BZH_BRANCHEN_SELECT = BZH_BRANCHEN.map(
  b => (
    {
      label: b.title,
      value: b.id
    }
  )
);

export const BZH_TRANSACTION_REASONS = [{"title":"Verkauf s\u00e4mtlicher Gesch\u00e4ftsanteile (100%)","code":"ANTEIL_100","id":"fcaad9fc-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Verkauf \u00fcber 50% der Gesch\u00e4ftsanteile","code":"ANTEIL_GT_50","id":"fcb148b4-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Verkauf unter 50% der Gesch\u00e4ftsanteile","code":"ANTEIL_LT_50","id":"fcb82a4e-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Verm\u00f6gensverkauf Gesamtbetrieb","code":"VERMOEGEN_ALL","id":"fcbe8f92-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Verm\u00f6gensverkauf Teilbetrieb","code":"VERMOEGEN_PART","id":"fcc4bbd8-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Verpachtung Gesamtbetrieb","code":"VERPACHTUNG_ALL","id":"fcd15f6e-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Verpachtung Teilbetrieb","code":"VERPACHTUNG_PART","id":"fcd6faf0-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"},{"title":"Sonstiges","code":"DIVERSE","id":"fcdaee6c-f4bf-11e9-942d-70be69d3e608","createdAt":"2019-10-22T13:35:02+02:00"}];

export const BZH_TRANSACTION_REASONS_SELECT = BZH_TRANSACTION_REASONS.map(
  b => (
    {
      label: b.title,
      value: b.id
    }
  )
);


export let BZH_COUNTRIES_LABELS = [
  'Österreich',
  'Deutschland',
  'Tschechien',
  'Slowakei',
  'Ungarn',
  'Slowenien',
  'Italien',
  'Schweiz'
];

export const BZH_COUNTRIES = [
  {
    label: 'Österreich',
    value: 'AT'
  },
  {
    label: 'Deutschland',
    value: 'DE'
  },
  {
    label: 'Tschechien',
    value: 'CZ'
  },
  {
    label: 'Slowakei',
    value: 'SK'
  },
  {
    label: 'Italien',
    value: 'IT'
  },
  {
    label: 'Schweiz',
    value: 'CH'
  }
]

export let BZH_COUNTRIES_CODES = [
  'AT',
  'DE',
  'CZ',
  'SK',
  'HU',
  'SI',
  'IT',
  'CH'
];

export let BZH_REGIONS_AT = [
  'Burgenland',
  'Kärnten',
  'Niederösterreich',
  'Oberösterreich',
  'Salzburg',
  'Steiermark',
  'Tirol',
  'Voralberg',
  'Wien',
  'keine nähere Angabe'
];

export let BZH_REGIONS_DE = [
  'Baden-Württemberg',
  'Bayern',
  'keine nähere Angabe'
];

export let BZH_LEGAL_FORMS = [{"title":"Gesellschaft mit beschr\u00e4nkter Haftung","abbreviation":"GmbH","code":"gmbh","id":"6a79e44a-f660-11e9-83b3-b0ed9522eb35","createdAt":"2019-10-24T15:15:57+02:00"},{"title":"Einzelunternehmen","abbreviation":"EPU","code":"epu","id":"a8c023ea-f660-11e9-83b3-b0ed9522eb35","createdAt":"2019-10-24T15:17:41+02:00"},{"title":"Kommanditgesellschaft","abbreviation":"KG","code":"kg","id":"a8c69ef0-f660-11e9-83b3-b0ed9522eb35","createdAt":"2019-10-24T15:17:42+02:00"},{"title":"Gesellschaft nach b\u00fcrgerlichem Recht","abbreviation":"GesbR","code":"gesbr","id":"a8cd3724-f660-11e9-83b3-b0ed9522eb35","createdAt":"2019-10-24T15:17:42+02:00"},{"title":"Aktiengesellschaft","abbreviation":"AG","code":"ag","id":"a8d3d002-f660-11e9-83b3-b0ed9522eb35","createdAt":"2019-10-24T15:17:42+02:00"},{"title":"Sonstige","abbreviation":"Sonstige","code":"sonstige","id":"UUID()","createdAt":"2021-12-17T21:53:36+01:00"}];

export let BZH_LEGAL_FORMS_SELECT = BZH_LEGAL_FORMS.map(
  l => ({
    label: l.title,
    value: l.id
  })
)
